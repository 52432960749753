<template>
  <div class="columns is-centered">
    <div class="column is-7">
      <div
        class="content-box"
        :class="{
          'frosted-background-static': $isSafari,
          'frosted-background': !$isSafari,
        }"
      >
        <div v-if="err !== null" class="notification is-danger">{{ err }}</div>

        <template v-if="!mdpOk">
          <div class="field has-addons" style="margin-top:3px;">
            <p class="control">
              <a class="button is-static is-small">mdp</a>
            </p>
            <p class="control">
              <input
                type="text"
                id="sketchy_liste_parties_username"
                class="input is-small"
                v-model="mdp"
                placeholder="Mot de passe"
                @keyup.enter="recherche"
                :disabled="reqEnCours"
              />
            </p>
          </div>
        </template>

        <div style="display: inline-block;margin-right: 4px;">
          <div class="field has-addons" style="margin-top:3px;">
            <p class="control">
              <a class="button is-static is-small">Recherche</a>
            </p>
            <p class="control">
              <input
                type="text"
                class="input is-small"
                v-model="keywords"
                placeholder="Mots clés"
                @keyup.enter="recherche"
                :disabled="reqEnCours"
              />
            </p>
          </div>
        </div>

        <div style="display: inline-block;margin-right: 4px;">
          <div class="field has-addons" style="margin-top:3px;">
            <p class="control">
              <input
                type="number"
                min="0"
                max="100"
                class="input is-small"
                v-model="pourcentageMin"
                placeholder="Nbresults"
                @keyup.enter="recherche"
                :disabled="reqEnCours"
                style="width: 60px;"
              />
            </p>
            <p class="control">
              <a class="button is-static is-small">%</a>
            </p>
          </div>
        </div>

        <div style="display: inline-block;margin-right: 4px;">
          <div class="field has-addons" style="margin-top:3px;">
            <p class="control">
              <a class="button is-static is-small">Résultats max</a>
            </p>
            <p class="control">
              <input
                type="number"
                min="0"
                max="100"
                class="input is-small"
                v-model="maxResults"
                @keyup.enter="recherche"
                :disabled="reqEnCours"
                style="width: 60px;"
              />
            </p>
          </div>
        </div>

        <div style="display: inline-block;">
          <button
            class="button is-small"
            @click="recherche"
            :class="{'is-loading': reqEnCours}"
            :disabled="reqEnCours || keywords.trim() === ''"
          >Rechercher
          </button>
        </div>

          <br/>

          <div style="margin-top: 12px;">
            <span v-if="meta !== null" style="color: #999;">
              {{ results.length }} résultats acceptables | {{ meta.nb_parties }} parties | {{ meta.nb_dessins }} dessins | {{ meta.duration_millis }}ms<br/>
            </span>

            <div class="columns is-fluid is-multiline">
              <div class="column is-6 pic-container" v-for="result in results">
                <img class="pic"
                     :src="$url_serveur + '/api/admin/giUlG4JFQsIPVL07YeoYwpKA4cDAXatUaLm7r7MS/dessin/' + result.dir + '/' + result.carnet_index + '/' + result.mot_index + '?p=' + mdp">
                <span class="pic-mot">{{ result.mot }}</span>
                <span class="pic-author">Dessiné par : {{ result.auteur }}</span>
                <span class="pic-match">Match : {{ result.score }}%</span>
                <span class="pic-salon">Salon : {{ result.nom_salon }}</span>
                <span class="pic-date">Le {{ (new Date(result.date * 1000)).toLocaleString() }}</span>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.content-box {
  margin-top: 16px;
  border: 1px solid rgba(0, 0, 0, 0.11);
  border-radius: 12px;
  padding: 1.25rem 2.5rem 1.25rem 1.5rem;
}

.pic-container {
  position: relative;

  .pic {
    display: block;

    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 8px;

    margin-left: 10px;
    margin-right: 10px;

    overflow: hidden;
    background-color: #fff;
  }

  .pic-mot {
    display: block;
    position: absolute;
    bottom: -58px;
    width: 100%;
    height: 70px;
    text-align: center;
    margin-right: 22px;
    color: #555;
    font-family: cabin_sketch_regular;
    font-size: 17px;
  }

  .pic-author {
    display: block;
    position: absolute;
    bottom: -38px;
    right: 0;
    margin-right: 22px;
    color: #aaa;
    font-family: cabin_sketch_regular;
    font-size: 16px;
  }

  .pic-date {
    display: block;
    position: absolute;
    bottom: -58px;
    right: 0;
    margin-right: 22px;
    color: #aaa;
    font-family: cabin_sketch_regular;
    font-size: 14px;
  }

  .pic-match {
    display: block;
    position: absolute;
    bottom: -38px;
    left: 20px;
    margin-right: 22px;
    color: #aaa;
    font-family: cabin_sketch_regular;
    font-size: 16px;
  }

  .pic-salon {
    display: block;
    position: absolute;
    bottom: -58px;
    left: 20px;
    margin-right: 22px;
    color: #aaa;
    font-family: cabin_sketch_regular;
    font-size: 16px;
  }

  margin-bottom: 65px;
}
</style>

<script>
export default {
  name: 'RechercheDessins',

  data() {
    return {
      mdp: '',
      mdpOk: false,

      keywords: '',
      pourcentageMin: '70',
      maxResults: '100',

      err: null,

      results: [],
      meta: null,

      reqEnCours: false,
    };
  },

  created() {
    this.mdp = this.$session._admin_liste_mdp;
    if (this.mdp !== null && this.mdp !== undefined && typeof this.mdp === 'string' && this.mdp.length > 0) {
      this.mdpOk = true;
    }
  },

  methods: {
    recherche() {
      if (this.keywords.trim() === '') return;
      if (this.reqEnCours) return;
      this.reqEnCours = true;

      this.$session._admin_liste_mdp = null;
      this.$axios.get(this.$url_serveur + '/api/admin/giUlG4JFQsIPVL07YeoYwpKA4cDAXatUaLm7r7MS/recherche_dessins', {
        params: {
          p: this.mdp,
          keywords: this.keywords,
          maxResults: parseInt(this.maxResults),
        }
      })
        .then((resp) => {
          this.reqEnCours = false;
          this.err = null;
          this.meta = resp.data.meta;
          this.mdpOk = true;
          this.$session._admin_liste_mdp = this.mdp;

          this.results = [];
          for (let i in resp.data.results) {
            if (resp.data.results[i].score > this.pourcentageMin) {
              this.results.push(resp.data.results[i])
            } else {
              break;
            }
          }
        })
        .catch((err) => {
          this.reqEnCours = false;
          this.err = err;
          this.mdpOk = false;
          this.$session._admin_liste_mdp = null;
        });
    },
  }
}
</script>